import { createSlice } from "@reduxjs/toolkit";

const DEFAULT_PARAMS = {
  SearchEquipment: "",
  EquipmentTag: "",
  EquipmentParentTag: "",
  AreaCodeId: "",
  UnitCodeId: "",
  AcRanking: "",
  IcRanking: "",
  PID: "",
  EquipmentStatusId: "",
  StartFrom: 0,
  FetchRecord: 100,
};

const notAssignedTagsSlice = createSlice({
  name: "notAssignedTags",
  initialState: {
    params: DEFAULT_PARAMS,
    currentPage: 0,
  },
  reducers: {
    setFilterParams: (state, action) => {
      state.params = action.payload;
    },
    resetFilterParams: (state) => {
      state.params = DEFAULT_PARAMS;
      state.currentPage = 0;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
});

export const { setFilterParams, resetFilterParams, setCurrentPage } =
  notAssignedTagsSlice.actions;

export const getFilterParams = (state) => state.notAssignedTags.params;
export const getCurrentPage = (state) => state.notAssignedTags.currentPage;

export default notAssignedTagsSlice.reducer;
